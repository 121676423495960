<template>
  <div>
    <div class="d-flex w-100 align-items-center justify-content-center" v-if="!hasSnapshot">
      <div class="text-center">
        <b-icon icon="shield" class="h1" />
        <p class="my-3">
          Ainda não há gestores estratégicos sincronizados para envio da pesquisa.
        </p>
        <b-button v-b-modal.select-employees-provider v-if="!is_provider">
          <b-icon icon="arrow-down-up" class="mr-2" />
          <span>Sincronizar agora</span>
        </b-button>
      </div>
    </div>

    <div v-else>
      <div class="d-flex align-items-center justify-content-start mb-3">
        <small class="mr-2">
          Ultima atualização {{ employees.updated_at | dateFull }}</small>
        <unicon name="redo" v-b-modal.select-employees-provider class="pointer small" style="font-size: 12px" height="16"
          width="16" />
      </div>
      <cards-list grid="4">
        <b-card v-for="(employee, index) in employees.employees" :key="index" class="mb-2 mr-2">
          <section class="d-md-block">
            <section>
              <strong class="d-block w-100">
                {{ employee.name }}
              </strong>
              <small class="mb-3 d-block">
                <b-icon icon="envelope" /> {{ employee.email }}
              </small>
              <small class="d-block">
                <strong>Função:</strong> {{ employee.position.name }}
              </small>
              <small class="d-flex w-100" :class="employee.status === 1 ? 'text-danger' : 'text-success'">
                <span v-if="employee.status === 1"> Não respondido</span>
                <span v-else>
                  Respondido em {{ employee.updated_at | dateFull }}</span>
              </small>
            </section>
          </section>
        </b-card>
      </cards-list>
    </div>

    <b-modal id="select-employees-provider" size="lg" centered scrollable hide-header hide-footer>
      <template #modal-header> </template>
      <div class="py-3">
        <ModalSelect @close="handleSetSelecteds" />
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ModalSelect from "./ModalSelect.vue";

export default {
  components: {
    ModalSelect,
  },
  computed: {
    ...mapGetters(["is_preview", "preview_personify", "user", "is_provider"]),
  },
  data() {
    return {
      hasSnapshot: false,
      search: "",
      selecteds: [],
      service_id: "",
      provider_id: "",
      homologationId: "",
      employees: [],
    };
  },
  methods: {
    ...mapActions(["set_snapshot", "get_snapshot", "show_loading"]),

    getSnapshot() {
      this.$bvModal.show("modal-generating-snapshot");
      this.loading = true;
      this.show_loading({
        loading: true,
      });
      this.get_snapshot({
        type: "questionaire-employee",
        service_id: this.service_id,
        provider_id: this.provider_id,
      }).then((response) => {
        this.$bvModal.hide("modal-generating-snapshot");
        if (!response?.updated_at) {
          this.hasSnapshot = false;
        } else {
          this.hasSnapshot = true;
          this.employees = response;
        }
        this.show_loading({
          loading: false,
        });
        this.loading = false;
      });
    },
    handleSetSelecteds(selecteds) {
      this.$bvModal.hide("select-employees-provider");
      this.set_snapshot({
        type: "questionaire-employee",
        service_id: this.service_id,
        provider_id: this.provider_id,
        data: selecteds.flatMap(({ id }) => [id]),
      }).then(() => {
        this.$bvModal.hide("modal-generating-snapshot");
        this.hasSnapshot = true;
        this.getSnapshot();
      });
    },
  },
  mounted() {
    this.service_id = this.$route.params.service_id;
    this.provider_id = this.$route.params.provider_id;
    this.homologationId = this.$route.params.homologation_id;
    this.getSnapshot();
  },
};
</script>
<style lang="scss">
.content-capacity {
  margin: 0 -10px;

  .card-security {
    display: inline-grid;
    user-select: none;
    margin: 0 10px;

    &.disabled {
      opacity: 0.4;
      filter: grayscale(5);
      cursor: not-allowed;
      pointer-events: none;
      user-select: none;
    }

    &.selected {
      border-color: $primary;

      .title {
        color: $primary;
      }
    }
  }
}
</style>
