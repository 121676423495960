<template>
  <div>
    <div v-if="employees.length > 0">
      <section class="w-100">
        <section>
          <h5 class="mb-0">Selecione até 5 gestores estratégicos para enviar a pesquisa</h5>
          <span>{{ selecteds.length }} selecionados</span>
        </section>

        <div class="input-search w-100 p-0 my-2">
          <b-form-input v-model="search" placeholder="Digite para pesquisar" class="mb-2" />
          <b-button variant="light" class="px-2 d-flex align-items-center absolute icon-action">
            <b-icon icon="search"></b-icon>
          </b-button>
        </div>
      </section>
      <cards-list grid="4" responsive="1" gap="12" class="content-security-modal">
        <b-card no-body @click="handleSelect(client)" class="mb-3 pointer card-security p-3 mx-1" :class="[
          selecteds.includes(client) && 'selected',
          selecteds.length === 5 && !selecteds.includes(client) && 'disabled',
        ]" v-for="(client, index) in employees" :key="index">
          <span class="ball-selection" :class="selecteds.includes(client) && 'selected'" />
          <div class="mr-md-4">
            <strong class="d-block title"> {{ client.name }} </strong>
            <span class="d-block mt-1">
              <b-icon icon="envelope" class="mr-2" />{{ client.email }}
            </span>
            <small class="text-muted">{{ client.situation }}</small>
          </div>
          <div>
            <div class="mt-3" v-if="client && client.position">
              <strong>Cargo</strong>
              <span class="pl-2">
                {{ client.position.name }}
              </span>
            </div>
          </div>
        </b-card>
      </cards-list>
      <div class="d-flex justify-content-end w-100">
        <b-button variant="primary" @click="sendSelecteds()">
          Salvar seleção
        </b-button>
      </div>
    </div>
    <div v-else class="text-center">
      <img src="@/assets/images/skills.png" class="d-block mx-auto mb-3" height="100" />
      <p>Não há gestor estratégico cadastrados para seleção</p>
      <router-link to="/my-enterprise/human-resources/employees/list">
        <b-button>Adicionar gestor estratégico</b-button>
      </router-link>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      search: "",
      empty: true,
      selecteds: [],
      items: [],
      employees: [],
    };
  },
  computed: {
    ...mapGetters(["is_preview", "preview_personify", "user"]),
  },
  methods: {
    ...mapActions(["get_all_employees"]),
    sendSelecteds() {
      this.$emit("close", this.selecteds);
    },
    handleSelect(sk) {
      if (this.selecteds.includes(sk)) {
        this.selecteds = this.selecteds.filter((item) => item !== sk);
        return;
      }
      if (this.selecteds.length === 5) {
        return;
      }
      this.selecteds.push(sk);
    },
    getAllEmployees() {
      this.get_all_employees().then(({ data }) => {
        this.empty = data?.total === 0;
        this.employees = data.results;
      });
    },
  },
  mounted() {
    this.getAllEmployees();
  },
};
</script>
<style lang="scss">
.content-security-modal {
  .card-security {
    &.disabled {
      opacity: 0.4;
      filter: grayscale(5);
      cursor: not-allowed;
      pointer-events: none;
      user-select: none;
    }

    &.selected {
      border-color: $primary;

      .title {
        color: $primary;
      }
    }
  }
}
</style>
